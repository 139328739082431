import { format, setMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import add from "date-fns/add";
import isBefore from "date-fns/isBefore";
import lightFormat from "date-fns/lightFormat";
import parseDate from "date-fns/parse";
import { Config } from "../config/api";
import { RevenueType } from "../models/enum/pixelRevenueType";
import { currencySymbols } from "./currencySymbols";

/**
 * verify that target has loaded children
 * @param {object} t
 * @returns {boolean}
 */
export const targetHasChildren = (t) => {
  return t.has_children && (!Object.hasOwn(t, "child") || t.child.length < 1);
};

(() => {
  function decimalAdjust(type, value, exp) {
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    const numericValue = +value;
    const numericExp = +exp;
    if (Number.isNaN(value) || !(typeof numericExp === "number" && numericExp % 1 === 0)) {
      return Number.NaN;
    }
    let adjustedValue = numericValue.toString().split("e");
    adjustedValue = Math[type](
      +`${adjustedValue[0]}e${adjustedValue[1] ? +adjustedValue[1] - numericExp : -numericExp}`,
    );
    adjustedValue = adjustedValue.toString().split("e");
    return +`${adjustedValue[0]}e${adjustedValue[1] ? +adjustedValue[1] + numericExp : numericExp}`;
  }

  // Round to closest
  if (!Math.round10) {
    Math.round10 = (value, exp) => decimalAdjust("round", value, exp);
  }
  // Round to down
  if (!Math.floor10) {
    Math.floor10 = (value, exp) => decimalAdjust("floor", value, exp);
  }
  // Round to up
  if (!Math.ceil10) {
    Math.ceil10 = (value, exp) => decimalAdjust("ceil", value, exp);
  }
})();

/**
 * prepare data to be used in Drop down
 * @param {array} items
 * @return {object}
 */
export const prepareDropdownData = (items) => {
  return items.map((item) => ({ key: item.id, text: item.name, value: item.id }));
};

export function Clean(obj) {
  const propNames = Object.getOwnPropertyNames(obj);
  for (const propName of propNames) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }

  return obj;
}

export function formatMoney1(amount, decimalCount = 0, decimal = ".", thousands = ",") {
  try {
    let adjustedDecimalCount = Math.abs(decimalCount);
    adjustedDecimalCount = Number.isNaN(adjustedDecimalCount) ? 2 : adjustedDecimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const absAmount = Math.abs(Number(amount) || 0).toFixed(adjustedDecimalCount);
    const i = Number.parseInt(absAmount).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (adjustedDecimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(adjustedDecimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.warn(e);
  }
}

/**
 * do a money format using intl lib
 * @param amount
 * @param currency
 * @param locale
 * @param decimalCount
 * @return {string}
 */
export function formatMoney(amount, currency, locale, decimalCount = 0) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: decimalCount,
  }).format(amount);
}

/**
 * get currency symbol
 * @param {string} currency_code
 * @param {array} currencies
 * @return {string}
 */
export function currencySymbol(currencyCode, currencies) {
  const currency = currencyCode || "";
  const r = currencies.filter((c) => c.currency_code.toLowerCase() === currency.toLowerCase());
  return r.length > 0 ? r[0].symbol : Config.currencySymbol;
}

/**
 * get full time zone name
 * @param {string} time_zone
 * @param {array} timezones
 * @return {string}
 */
export function getTimeZone(timeZone, timezones) {
  const r = timezones.filter((t) => t.value.toLowerCase() === timeZone.toLowerCase());
  return r.length > 0 ? r[0].title : timeZone;
}

/**
 * do a number format using intl lib
 * @param amount
 * @param {string} locale
 * @param decimalCount
 * @return {string}
 */
export function formatNumber(amount, locale = "en-IN", decimalCount = 0, round = false) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: round ? 0 : decimalCount,
    maximumFractionDigits: round ? 0 : decimalCount + 2,
  }).format(amount);
}

/**
 * Check that provided attribute is digit
 * @param {float|string|number} num
 * @return {boolean}
 */
export function isDigit(num) {
  return !(isEmpty(num) || Number.isNaN(Number(num)));
}

/**
 * check user has internal type
 * @param {object} user
 * @return {boolean}
 */
export const isInternal = (user) => {
  return user.role && user.type?.toLowerCase() === "internal";
};

/**
 * verify that jwt token has admin rights
 * @param {object} user
 * @return {boolean}
 */
export const isAdmin = (user) => {
  return user.role?.toLowerCase() === "admin";
};

/**
 * verify that jwt token has reporter rights
 * @param {object} u
 * @return {boolean}
 */
export const isReporter = (user) => {
  return user.role?.toLowerCase() === "reporter";
};

/**
 * parse jwt token
 * @param {string} token
 * @return {object}
 */
export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

/**
 * Check that value is empty
 * @param {float|string|number} num
 * @return {boolean}
 */
export function isEmpty(num) {
  return String(num).length < 1;
}

export function isInt(n) {
  return isDigit(n) && n % 1 === 0;
}

/**
 * Check that value has the only numbers inside
 * @param value
 */
export function hasOnlyNumbers(value) {
  return value.search(/\D/g) === -1;
}

/**
 * Check that value is a valid http link
 * @param {string} value
 * @returns {boolean}
 */
export function isValidHttpUrl(value) {
  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function isFloat(n) {
  return isDigit(n) && n % 1 !== 0;
}

/**
 * Check that we met error for the key
 * @param {object} error
 * @param {string} error.dataPath
 * @param {string} key
 * @return {boolean}
 */
export function hasError(error, key) {
  return error?.dataPath && Boolean(~error.dataPath.indexOf(key));
}

/**
 * Transform date format from javascript to API
 * @param {string} datetime
 * @param {string} date_format
 * @return {string}
 */
export function getApiDate(dateTime, dateFormat = "yyyy-MM-dd") {
  return lightFormat(new Date(Date.parse(dateTime)), dateFormat);
}

/**
 * verify that server response is ok
 * @param {object} r
 */
export const isOk = (r) => {
  return r?.meta?.status === "ok";
};

/**
 * Return the date parsed from string returned by API.
 * @param {string} datetime
 * @return {Date}
 */
export function parseAPIDateTime(datetime) {
  return parseDate(datetime, "yyyy-MM-dd HH:mm", new Date());
}

/**
 * Verify that provided date time is after now()
 * @param {DateTime} datetime in local timezone, compares it to now Date() which is also in local time zone
 * @returns {boolean}
 */
export function isApiDateTimeExpired(datetime) {
  return isBefore(datetime, new Date());
}

/**
 * Return the date parsed from string returned by API including timezone
 * @param {string} datetime
 * @param {string} timezone
 * @return {Date}
 */
export function parseAPIDateTimeWithTimeZone(datetime, timezone) {
  return utcToZonedTime(datetime, timezone);
}

/**
 * Return the formatted datetime string for API.
 * @param {Date} date
 * @return {string}
 */
export function formatAPIDateTime(date) {
  return lightFormat(date, "yyyy-MM-dd HH:mm");
}

/**
 * Return the date parsed from string returned by API.
 * @param {string} datetime
 * @return {Date}
 */
export function parseAPIDate(dateTime, dateFormat = "yyyy-MM-dd") {
  return parseDate(dateTime, dateFormat, new Date());
}

/**
 * Return the formatted date string for API.
 * @param {Date} date
 * @return {string}
 */
export function formatAPIDate(date) {
  return lightFormat(date, "yyyy-MM-dd");
}

/**
 * @param {Date} date a date object in local timezone
 * @return {string} a formatted string of the UTC date object in the format 'yyyy-MM-dd HH:mm:ss' for API call
 */
export function formatDateToISO(date, withoutSeconds = false) {
  const dateFormat = withoutSeconds ? "yyyy-MM-dd'T'HH:mmXXX" : "yyyy-MM-dd'T'HH:mm:ssXXX";
  return format(date, dateFormat);
}

/**
 * @param {string} utcDate a string that is in UTC typically from API
 * @return {Date} a date object that has been converted to local system timezone
 */
export function parseAPIDateTimeToLocalTZDateObj(utcDate) {
  const utcDateString = `${utcDate}Z`;
  const utcDateObject = new Date(utcDateString);
  const localDate = utcToZonedTime(utcDateObject, Intl.DateTimeFormat().resolvedOptions().timeZone);

  return localDate;
}

/**
 * get checked segments
 * @param audiences
 * @return {object}
 */
export const getCheckedSegments = (audiences) => {
  const segments = {};
  const tree = (node) => {
    return node.map((item) => {
      if (Object.hasOwn(item, "checked") && item.checked) {
        if (!segments[item.category_slug]) {
          segments[item.category_slug] = [];
        }

        segments[item.category_slug].push(item.id);
      } else if (item.child) {
        item.child = tree(item.child);
      }

      return item;
    });
  };
  tree(audiences);

  return segments;
};

export function readablizeNumber(number) {
  const numberLength = (Math.log(number) * Math.LOG10E) | 0;
  let decimals = numberLength % 3;
  const e = 10 ** (numberLength - decimals);
  const s = ["", "K", "M", "B"];
  const element = Math.floor(Math.log(number) / Math.log(1000));

  let result = number;
  if (decimals === 0) {
    decimals = 2;
  } else {
    decimals = 0;
  }

  if (number >= 1000) {
    result = `${Number(Math.round10(number / e, -1 * decimals)).toFixed(decimals)}${s[element]}`;
  }

  return result;
}

/**
 * Get utc date
 * @param {Date} local_date
 * @return {Date}
 */
export function getUTCDate(localDate) {
  const tmp = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0));
  tmp.setHours(0);
  return tmp;
}

/**
 * Make valid time string from pieces
 * @param hour
 * @param minute
 * @param {boolean} full_format
 * @return {string}
 */
export function getApiTime(hour, minute, fullFormat = true) {
  let convertedHour = hour;
  let convertedMinute = minute === null ? "" : minute.toString();

  convertedHour = convertedHour < 10 ? `0${convertedHour}` : convertedHour;
  convertedMinute = convertedMinute < 10 ? `0${convertedMinute}` : convertedMinute;

  return fullFormat ? `${convertedHour}:${convertedMinute}:00` : `${convertedHour}:${convertedMinute}`;
}

/**
 * add leading zero
 * @param {number} num
 * @return {string}
 */
export function pad2(num) {
  return (num < 10 ? "0" : "") + num;
}

/**
 * get array of weekly days
 * @return {Array}
 */
export function getWeeklyDays() {
  return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
}

export const isNil = (val) => val == null;
export const isUndefined = (val) => val === undefined;

/**
 * convert code to symbol
 * @param {String} code
 * @return {String}
 */
export function currencyCodeToSymbol(code) {
  return code ? currencySymbols[code.toUpperCase()] : "";
}

/**
 * Rename a key in the object
 * @param {object} item
 * @param {string} keyFrom
 * @param {string} keyTo
 */
export function renameKey(item, keyFrom, keyTo) {
  if (keyFrom === keyTo) {
    return;
  }

  if (keyFrom in item) {
    item[keyTo] = item[keyFrom];
    delete item[keyFrom];
  }
}

/**
 * get date next to closest with interval 15 min
 * @param {Date} date
 * @return {Date}
 */
export function getClosestTo15Date(date) {
  const minutes = date.getMinutes();
  const roundedMinutes = getClosestTo15Delta(minutes);
  return setMinutes(date, roundedMinutes);
}

/**
 * get tomorrow
 * @return {Date}
 */
export function getTomorrowDate() {
  return add(new Date(), { days: 1 });
}

/**
 * get closest delta number to 15
 * @param n
 * @return {number}
 */
export function getClosestTo15Delta(n) {
  return Math.ceil(n / 15) * 15;
}

export const campaignHasPGType = (item) => Object.hasOwn(item, "type") && item.type.toLowerCase() === "pg";
export const campaignHasCTV = ({ ctv } = {}) => ctv === true;
export const campaignHasOnSite = ({ onsite } = {}) => onsite === true;

/**
 * Clone `array` and move the item to a given position.
 * @param {*[]} array
 * @param {number} from
 * @param {number} to
 * @returns {*[]} modified array
 */
export const arrayMove = (array, from, to) => {
  const clone = [...array];

  if (from >= 0 && from < clone.length) {
    const endIndex = to < 0 ? clone.length + to : to;
    const [item] = clone.splice(from, 1);
    clone.splice(endIndex, 0, item);
  }
  return clone;
};

/**
 * capitalize 1st letter in string
 * @param {string} value
 * @return {string}
 */
export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * rebuild public_client variable into human readable format
 * replace '_' with ' ', update all words with capital first letter
 * @param {string} value
 * @returns {string}
 */
export const getReadableClientName = (value) => {
  return value
    .replaceAll("_", " ")
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
};

/**
 * Extracts error message from an Error or server response.
 * @param {Error|object} e error object to convert
 * @returns {string|null}
 */
export function errorToMessage(e) {
  if (e?.error?.message) return e.error.message;
  if (e.message) return e.message;
  return null;
}

/**
 * format campaign name
 * @param {string} campaign_type
 * @return {string}
 */
export const getCampaignType = (campaignType) => {
  return `${campaignType.toUpperCase()}/PMP/OPEN`;
};

/**
 * generate campaign type based on LD flags
 * @param pmp
 * @param audience
 * @param open_supply
 * @param lang
 * @return {string}
 */
export const getCampaignTypeBasedOnLD = (pmp, audience, openSupply, lang) => {
  const supplyLabels = {
    pmp: "PMP",
    audience: lang.campaign.supply_type,
    open_supply: "Open Supply",
  };

  /**
   * generate supply text based on LD flags
   * @return {string}
   */
  const getOptionText = () => {
    const option = [];
    if (pmp) {
      option.push(supplyLabels.pmp);
    }

    if (audience) {
      option.push(supplyLabels.audience);
    }

    if (openSupply) {
      option.push(supplyLabels.open_supply);
    }

    return option.join(" / ");
  };

  return getOptionText();
};

/**
 * check that property of the object exists and its not empty
 * @param {object} data
 * @param {string} property
 * @returns {boolean}
 */
export const isExists = (data, property) => {
  return Object.hasOwn(data, property) && data[property].toString().length > 0;
};

/**
 * Generate common pixel link
 * @param {object} values
 * @return {string}
 */
export const generatePixelSnippet = (values) => {
  if (!values) {
    return "";
  }

  let codeSnippetLink = `<script async src='//pixel.mathtag.com/event/js?mt_id=${values.id}&mt_adid=${values.advertiser_id}&mt_exem=&mt_excl=&s1=&s2=&v1=&v2='></script>`;

  if (values?.revenue_enabled) {
    if (values.revenue_type === RevenueType.FIXED) {
      codeSnippetLink = `<script async src='//pixel.mathtag.com/event/js?mt_id=${values.id}&mt_adid=${values.advertiser_id}&mt_exem=&mt_excl=&s1=&s2=&v1=&v2='></script>`;
    } else if (values.revenue_type === RevenueType.DYNAMIC) {
      codeSnippetLink = `<script async src='//pixel.mathtag.com/event/js?mt_id=${values.id}&mt_adid=${values.advertiser_id}&mt_exem=&mt_excl=&s1=&s2=&v1=&v2='></script>`;
    }
  }

  return codeSnippetLink;
};

/**
 * Format bytes as human-readable text.
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  let adjustedBytes = bytes;
  do {
    adjustedBytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(adjustedBytes) * r) / r >= thresh && u < units.length - 1);

  return `${adjustedBytes.toFixed(dp)} ${units[u]}`;
}

/**
 * Gets list of reporting period for Date Window.
 * @param intl intl.
 *
 * @return array of reporting periods
 */
export const getReportingPeriods = (intl) => {
  return [
    {
      key: "yesterday",
      text: intl.formatMessage({
        id: "DATE_RANGE_YESTERDAY",
        defaultMessage: "Yesterday",
      }),
      value: "yesterday",
    },
    {
      key: "last_7_days",
      text: intl.formatMessage({
        id: "DATE_RANGE_7DAYS",
        defaultMessage: "Last 7 Days",
      }),
      value: "last_7_days",
    },
    {
      key: "last_14_days",
      text: intl.formatMessage({
        id: "DATE_RANGE_14DAYS",
        defaultMessage: "Last 14 Days",
      }),
      value: "last_14_days",
    },
    {
      key: "last_30_days",
      text: intl.formatMessage({
        id: "DATE_RANGE_30DAYS",
        defaultMessage: "Last 30 Days",
      }),
      value: "last_30_days",
    },
    {
      key: "month_to_date",
      text: intl.formatMessage({
        id: "DATE_RANGE_MONTH_TO_DATE",
        defaultMessage: "Month to Date",
      }),
      value: "month_to_date",
    },
    {
      key: "custom",
      text: intl.formatMessage({
        id: "DATE_RANGE_CUSTOM",
        defaultMessage: "Custom",
      }),
      value: "custom",
    },
  ];
};

/**
 * Gets different aggregations for Reporting.
 * @param intl intl.
 *
 * @return array of time rollups for reporting aggregation
 */
export const getTimeRollUps = (intl) => {
  return [
    {
      key: "by_day",
      text: intl.formatMessage({
        id: "REPORTING_AGGREGATION_BY_DAY",
        defaultMessage: "By Day",
      }),
      value: "by_day",
    },
    {
      key: "by_week",
      text: intl.formatMessage({
        id: "REPORTING_AGGREGATION_BY_WEEK",
        defaultMessage: "By Week",
      }),
      value: "by_week",
    },
    {
      key: "by_month",
      text: intl.formatMessage({
        id: "REPORTING_AGGREGATION_BY_MONTH",
        defaultMessage: "By Month",
      }),
      value: "by_month",
    },
    {
      key: "all",
      text: intl.formatMessage({
        id: "REPORTING_AGGREGATION_ALL",
        defaultMessage: "All",
      }),
      value: "all",
    },
  ];
};

/**
 * Formats frequency cap for display.
 * @param intl intl.
 * @param campaignFrequencyOptimization bool
 * @param campaignFrequencyType str
 * @param campaignFrequencyAmount int
 * @param campaignFrequencyInterval str
 * @return a str representing the frequency cap ex ASAP 1 / day
 */
export const formatFrequencyCap = (
  intl,
  campaignFrequencyOptimization,
  campaignFrequencyType,
  campaignFrequencyAmount,
  campaignFrequencyInterval,
) => {
  if (campaignFrequencyOptimization) {
    return intl.formatMessage({
      id: "FREQUENCY_CAP_OPTIMIZED",
      defaultMessage: "Optimized",
    });
  }

  if (campaignFrequencyType === "no-limit") {
    return intl.formatMessage({
      id: "FREQUENCY_NO_CAP",
      defaultMessage: "No Cap",
    });
  }

  const frequencyTypeMessage =
    campaignFrequencyType === "asap"
      ? campaignFrequencyType.toUpperCase()
      : capitalizeFirstLetter(campaignFrequencyType);

  return `${frequencyTypeMessage} ${campaignFrequencyAmount} / ${campaignFrequencyInterval}`;
};

/**
 * Determines how many decimal places for each currency
 * @param currency str
 * @return a float representing decimal places
 */
export const currencyDecimalPlaces = (currency) => {
  const zeroDecimalCurrencies = [
    "JPY", // Japanese Yen
    "KRW", // South Korean Won
    "IDR", // Indonesian Rupiah
    "VND", // Vietnamese Dong
    "ISK", // Icelandic Krona
  ];
  const defaultDecimalValue = 0.01; // common 2 decimal places for most currencies

  return zeroDecimalCurrencies.includes(currency) ? 1 : defaultDecimalValue;
};

/**
 * Convert XML to JSON
 * @param xml str | object
 * @return JSON object
 */
const parseXmlString = (xmlString) => {
  const parser = new DOMParser();
  return parser.parseFromString(xmlString, "text/xml");
};

const processAttributes = (parsedXml) => {
  const attributes = {};
  for (let j = 0; j < parsedXml.attributes.length; j++) {
    const attribute = parsedXml.attributes.item(j);
    attributes[attribute.nodeName] = attribute.nodeValue;
  }
  return attributes;
};

const processChildNodes = (parsedXml) => {
  const obj = {};
  for (let i = 0; i < parsedXml.childNodes.length; i++) {
    const item = parsedXml.childNodes.item(i);
    const nodeName = item.nodeName;

    // Skip text nodes with empty values
    if (item.nodeType === 3 && item.nodeValue.trim() === "") {
      continue;
    }

    // If the node is CDATA, store it directly
    if (item.nodeType === 4) {
      obj["#cdata"] = item.nodeValue; // Store CDATA in a dedicated key
      continue;
    }

    // If the node doesn't exist in the object, add it
    if (typeof obj[nodeName] === "undefined") {
      obj[nodeName] = xmlToJson(item);
    } else {
      // If it exists, turn it into an array and add the new item
      if (!Array.isArray(obj[nodeName])) {
        obj[nodeName] = [obj[nodeName]];
      }
      obj[nodeName].push(xmlToJson(item));
    }
  }
  return obj;
};

export const xmlToJson = (xml) => {
  // If the input is a string, parse it into an XML DOM object
  const parsedXml = typeof xml === "string" ? parseXmlString(xml) : xml;

  if (!parsedXml) return null;

  const obj = {};

  if (parsedXml.nodeType === 1) {
    if (parsedXml.attributes.length > 0) {
      obj["@attributes"] = processAttributes(parsedXml);
    }
  } else if (parsedXml.nodeType === 3) {
    // If the node is a text node, return its value
    return parsedXml.nodeValue.trim(); // Trim to avoid excessive whitespace
  } else if (parsedXml.nodeType === 4) {
    // CDATA Section: nodeType 4
    return parsedXml?.nodeValue; // Return the value inside CDATA
  }

  if (parsedXml.hasChildNodes()) {
    Object.assign(obj, processChildNodes(parsedXml));
  }

  return obj;
};

/*
 * Changes key name for frequency cap related keys (frequency_optimizaton -> campaignFrequencyOptimization)
 * @param object obj
 * @return new obj but with frequency keys renamed
 */
export const renameFrequencyKeys = (obj) => {
  return Object.entries(obj).reduce((newObj, [key, value]) => {
    if (key.startsWith("frequency")) {
      const newKey = key.replace("frequency", "campaign_frequency");
      newObj[newKey] = value;
    } else {
      newObj[key] = value;
    }
    return newObj;
  }, {});
};

/**
 * remove empty string keys
 * @param {object} obj
 * @return {object}
 */
export function removeEmptyKeys(obj) {
  for (const key in obj) {
    if (obj[key] === "" || obj[key] === null) {
      delete obj[key];
    }
  }

  return obj;
}
